import React from 'react';

import { Box } from '@core';
import { ImageCore, Text } from '@components';

const PerkCard = ({ title, image }) => {
  return (
    <Box py={32} px={24}>
      <ImageCore src={image} alt={title} maxHeight={270} />
      <Text.Subheading2 textAlign="left" my={24}>
        {title}
      </Text.Subheading2>
    </Box>
  );
};

export default PerkCard;
